import ApiService from "@/services/api.service";

class CustomError extends Error {
	constructor(status, data) {
		super(data); // Llama al constructor de la clase Error
		this.status = status;
		this.data = data;
		this.name = "CustomError";
	}
}

const AccountService = {
	// Function to retrieve account data by UUID
	getAccount(uuid) {
		return ApiService.get(`/accounts/${uuid}`);
	},

	// Function to update account data by UUID
	updateAccount(uuid, accountData) {
		return ApiService.put(`/accounts/update/${uuid}`, accountData);
	},

	async setInitialLocalAccount() {
		// Intenta obtener una cuenta previamente seleccionada de localStorage
		let storedAccount = localStorage.getItem("account");

		// Obtener las cuentas del usuario actual
		var response = await this.getCurrentUserAccounts();
		if (response.data.accounts && Array.isArray(response.data.accounts) && response.data.accounts.length > 0) {
			let accounts = response.data.accounts;

			if (storedAccount) {
				storedAccount = JSON.parse(storedAccount);

				// Verifica si la cuenta almacenada está en la lista de cuentas del usuario
				const accountExists = accounts.some((account) => account.uuid === storedAccount.uuid);

				if (accountExists) {
					// La cuenta almacenada pertenece al usuario, así que la usamos
					return storedAccount;
				} else {
					// La cuenta almacenada no pertenece al usuario, selecciona una nueva
					this.setLocalAccount(accounts[0]); // Asume que setLocalAccount guarda la cuenta en localStorage
					return accounts[0];
				}
			} else {
				// No hay cuenta en localStorage, establece la primera cuenta del usuario
				this.setLocalAccount(accounts[0]);
				return accounts[0];
			}
		} else {
			throw new CustomError(401, "User does not have any accounts.");
		}
	},

	setLocalAccount(account) {
		localStorage.setItem("account", JSON.stringify(account));
	},

	async getCurrentUserAccounts() {
		return await ApiService.get("/users/me/accounts")
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return {
					status: error.response.status,
					data: error.response.data,
				};
			});
	},
};

export default AccountService;
