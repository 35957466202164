<template>
    <aside id="sidebar" class="main-sidebar" :class="{ 'show': sidebarVisible }">
        <nav class="sidebar-nav">
            <ul>
                <router-link v-for="item in menuItems" :key="item.name" :to="item.route" class="nav-link">
                    <li :class="{ active: isActive(item) }">
                        <i :class="item.icon"></i>
                        <span>{{ item.name }}</span>
                    </li>
                </router-link>
            </ul>
        </nav>
    </aside>
</template>

<script>

export default {
    data() {
        return {
            menuItems: [
                { name: 'Inicio', route: '/', icon: 'fa-regular fa-house-blank' },
                { name: 'Estadísticas', route: '/dashboard', icon: 'fa-regular fa-chart-line-up' },
                { name: 'Alojamientos', route: '/accommodations', icon: 'fa-sharp fa-regular fa-hotel' },
                { name: 'Reservas', route: '/agencies', icon: 'fa-regular fa-calendar' },
                { name: 'Agencias', route: '/agencies', icon: 'fa-regular fa-cube' },
                { name: 'Marketplace', route: '/marketplace', icon: 'fa-regular fa-bag-shopping' },
                { name: 'Noticias', route: '/news', icon: 'fa-regular fa-newspaper' },
                { name: 'Usuarios', route: '/users', icon: 'fa-regular fa-user-large' },
                { name: 'Ajustes', route: '/settings', icon: 'fa-regular fa-gear' }
            ],
            sidebarVisible: true,
        };
    },
    methods: {
        isActive(item) {
            return this.$route.path === item.route;
        }
    },
};
</script>

<style scoped>
.main-sidebar {
    width: 200px;
    background-color: #F9F9F9;
    color: #fff;
    height: 100vh;
    position: fixed;
    margin-top: 70px;
    overflow: auto;
    left: -200px;
    transition: left 0.3s ease
}

.main-sidebar.show {
    left: 0;
}


.sidebar-nav ul {
    list-style: none;
    padding: 10px;
    margin: 0;
}

.sidebar-nav ul li {
    padding: 10px 10px;

}

.sidebar-nav ul .nav-link {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
}

.sidebar-nav ul .nav-link li {
    display: flex;
    align-items: center;
}

.sidebar-nav ul .nav-link:hover {
    color: var(--primary-color);
}

.sidebar-nav ul .nav-link i {
    position: absolute;
    color: var(--bs-text-gray);
}

.sidebar-nav ul .nav-link:hover i {
    color: var(--primary-color);
}

.sidebar-nav ul .nav-link span {
    margin-left: 25px;
}

.sidebar-nav ul .nav-link.active {
    background-color: #555;
    color: var(--primary-color);
}

@media screen and (max-width: 992px) {

    .main-sidebar {
        margin-top: 60px;
    }
}
</style>