import { createWebHistory, createRouter } from "vue-router";
import store from "@/store";
import AuthService from "@/services/auth.service";

import authRoutes from "./routes/auth";
import appRoutes from "./routes/app";
import appAccommodationfrom from "./routes/accommodation";

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("@/views/App/Dashboard/Dashboard.vue"),
		meta: { requiresAuth: true },
	},
];

routes.push(...authRoutes);
routes.push(...appRoutes);
routes.push(...appAccommodationfrom);

const router = new createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 };
	},
});

// Add a global navigation guard to check authentication
router.beforeEach(async (to, from, next) => {
	const isAuthenticated = AuthService.isLoggedIn();
	let userAccount = store.getters["user/currentAccount"];
	let userData = store.getters["user/currentUser"];
	let userRole = store.getters["user/userRole"];

	if (isAuthenticated) {
		// Carga los roles solo si es necesario
		if (!userAccount || userAccount == undefined) {
			await store.dispatch("user/fetchUserCurrentAccount");
			userAccount = store.getters["user/currentAccount"];
		}

		// Carga los roles solo si es necesario
		if (!userData) {
			await store.dispatch("user/fetchUser");
			userData = store.getters["user/currentUser"];
		}

		// Carga los roles solo si es necesario
		if (!userRole) {
			await store.dispatch("user/fetchUserRole");
			userRole = store.getters["user/userRole"];
		}
	}

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		// Check if the route requires authentication
		if (!isAuthenticated) {
			// If the user is not authenticated, redirect to /login
			next("/login");
		} else if (to.path === "/login" && isAuthenticated) {
			// If the user is authenticated and tries to access the login page, redirect to the home page
			next("/");
		} else if (to.meta.requiresAdmin && !userRole != "account_admin") {
			// Check if the user has the 'admin' role to access admin routes
			next("/");
		} else {
			next();
		}
	} else {
		// Public routes, allow navigation
		next();
	}
});

export default router;
